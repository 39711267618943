import { Header } from "../components/header";
import { useTheme } from "../hooks/useTheme";
import { GkeyShowcase } from "./Showcase";

export const GkeyPrivacyPolicy = () => {

  const { isDarkMode, handleToggle } = useTheme();

  return (
    <>
    <Header isDarkMode={isDarkMode} toggleDarkMode={handleToggle} />
    <div className="max-w-4xl mx-auto p-6">
        <GkeyShowcase />

        <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
        <p className="mb-4"><strong>Effective Date:</strong> June 10, 2024</p>

        <h2 className="text-2xl font-semibold mb-2">1. Introduction</h2>
        <p className="mb-4">
            Welcome to G-Key (the "App"), developed by Galo Berlyn Garlejo ("we," "our," "us"). Your privacy is important to us. This Privacy Policy explains how we collect, use, and protect your information when you use our App.
        </p>

        <h2 className="text-2xl font-semibold mb-2">2. Information We Collect</h2>
        <p className="mb-4">
            <strong>Personal Information:</strong> We do not collect personal information that can directly identify you, such as your name, email address, or phone number.
        </p>
        <p className="mb-4">
            <strong>Password Data:</strong> The passwords you store in the App are encrypted and stored locally on your device. We do not have access to this data.
        </p>
        <p className="mb-4">
            <strong>Backup Files:</strong> When you create a backup using .gkey or CSV files, these files are stored locally on your device or in your chosen storage location. We do not have access to these backup files.
        </p>

        <h2 className="text-2xl font-semibold mb-2">3. How We Use Your Information</h2>
        <p className="mb-4">
            <strong>Master Password Protection:</strong> Your passwords are protected using a master password that only you know. We do not have access to this master password.
        </p>
        <p className="mb-4">
            <strong>Offline Storage:</strong> All your password data is stored locally on your device and is not transmitted to our servers or any third-party servers.
        </p>

        <h2 className="text-2xl font-semibold mb-2">4. Data Sharing</h2>
        <p className="mb-4">
            We do not share your data with any third parties. Your password data and backup files remain solely on your device unless you choose to share them yourself.
        </p>

        <h2 className="text-2xl font-semibold mb-2">5. Security</h2>
        <p className="mb-4">
            We take reasonable measures to protect your information from unauthorized access, use, or disclosure. Your password data is encrypted, and we continuously work to improve our security practices.
        </p>

        <h2 className="text-2xl font-semibold mb-2">6. Your Choices</h2>
        <p className="mb-4">
            <strong>Backup and Restore:</strong> You have the option to create and restore backups of your password data using .gkey or CSV files. Ensure that these files are stored securely to prevent unauthorized access.
        </p>
        <p className="mb-4">
            <strong>Master Password:</strong> Choose a strong master password and keep it confidential. We do not store or have access to your master password.
        </p>

        <h2 className="text-2xl font-semibold mb-2">7. Changes to This Privacy Policy</h2>
        <p className="mb-4">
            We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy within the App. Your continued use of the App after any changes indicates your acceptance of the updated Privacy Policy.
        </p>

        <h2 className="text-2xl font-semibold mb-2">8. Contact Us</h2>
        <p className="mb-4">
            If you have any questions or concerns about this Privacy Policy, please contact us at:
        </p>
        <p className="mb-4"><strong>Email:</strong> gbgarlejo@gmail.com</p>
    </div>
    </>
);

}