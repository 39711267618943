import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { GkeyPrivacyPolicy } from './gkey/PrivacyPolicy';
import { Humble } from './humble/Humble';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/humble",
    element: <Humble />
  },
  {
    path: "/gkey-app/privacy-policy",
    element: <GkeyPrivacyPolicy />
  }
]);


root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();
