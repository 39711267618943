import { Header } from "../components/header";
import { useTheme } from "../hooks/useTheme";
export const Humble = () => {

  const { isDarkMode, handleToggle } = useTheme();

  return (
    <>
    <Header isDarkMode={isDarkMode} toggleDarkMode={handleToggle} />
    <div className="max-w-4xl mx-auto p-6">
        <h2 className="text-2xl font-semibold mb-2">Humble's Booking website</h2>
        <p className="mb-4">
        Humble Sustainability ("Humble"), a Philippines-based climate tech startup, has announced a significant milestone in its journey towards creating a circular economy in Southeast Asia. The company has successfully closed a pre-Series A funding round led by Gobi Partners (through its Gobi-Core Philippine Fund) alongside major investors ADB Ventures (ADBV), the Asian Development Bank's venture arm, and the Philippines government through the National Development Company (NDC). This strategic investment will enable Humble to expand its operations beyond the Philippines and enhance its technology offerings, particularly by developing its proprietary Climate Dashboard platform.
        </p>

        <p>
          Read more about Humble's journey and the funding round here: <a href="https://www.prnewswire.com/apac/news-releases/humble-sustainability-secures-strategic-investment-from-adb-ventures-to-drive-expansion-across-southeast-asia-302242829.html" rel="noreferrer" target="_blank">https://www.humblesustainability.com/blog/humbles-booking-website-goes-live</a>
        </p>

        <video autoPlay controls>
          <source src="/files/humble.webm" type="video/webm" />
        </video>

      
    </div>
    </>
);

}