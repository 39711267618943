import hammer from '../../assets/images/pos.jpeg';
import cal from '../../assets/images/cal.png';
import qr from '../../assets/images/qr.avif';
import gkey from '../../assets/images/512.png';
import humble from '../../humble/humble_logo.png';
import oky from '../../assets/images/oky.webp';

export const Projects = () => {
  const data = [
    {
      title: 'Gkey Password Manager',
      link: '/gkey-app/privacy-policy',
      img: gkey,
      isNewTab: true,
      description: 'A simple password manager app built by myself',
      tech: 'React Native - Expo SDK 50'
    },
    {
      title: 'Booking website',
      link: '/humble',
      img: humble,
      isNewTab: true,
      description: 'A booking website for humblesustainability.com',
      tech: 'React/Node.js'
    },
    {
      title: 'Oky Period Tracker App',
      link: 'https://play.google.com/store/apps/details?id=com.oky.ph',
      img: oky,
      isNewTab: true,
      description: 'A simple period tracker app for Oky - Plan International PH',
      tech: 'Full Stack Developer Consultant - React Native'
    },
    {
      title: 'POS',
      link: 'https://pos-demo.galogarlejo.dev',
      img: hammer,
      isNewTab: true,
      description: 'A point of sale system for a local hardware store in Baguio City, Philippines',
      tech: 'Full Stack Developer Contractor -React.js • Node.js • MySQL'
    },
    {
      title: 'QR App',
      link: 'https://qr.galogarlejo.dev',
      description: 'A simple qr scanner and generator used during the Covid pandemic to scan qr forms or generate qrs',
      tech: 'React.js',
      img: qr,
    },
    {
      title: 'Get Weekday',
      link: 'https://www.npmjs.com/package/get-date-based-on-weekday',
      description: 'A simple library to manage dates',
      tech: 'JavaScript',
      img: cal,
    }
  ]

  return (
    <div className="mx-auto">
      <div className='w-full'>
        <h2 className="text-black dark:text-white text-lg mb-5">Samples</h2>
      </div>
      <div className="">
        { data.map(d => (
          <div key={d.link} className="flex">
            <div className="w-5/12">
              <h3 className="text-black dark:text-white text-sm">
                {d.title}
              </h3>
            </div>
            <div className="w-7/12 mb-5">
              <p className="text-black dark:text-grey text-sm mb-3">
                <a href={d.link} rel="noreferrer" className="text-blue mb-1" target={d.isNewTab ? '_blank' : ''}>
                  <img src={d.img} alt="hammer" className="mb-2" style={{ width: '15%', borderRadius: '100%' }} />
                </a>
                {d.description}
              </p>
              <p className="text-black dark:text-grey text-sm mb-3">{d.tech} </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}