import gkey from './gkey.png';

export const GkeyShowcase = () => {
  return (
    <div className="mb-10">
        <div>
            <img className="h-auto max-w-full rounded-lg mb-1" src={gkey} alt=""/>
            <h4 className="text-3xl text-green-200 underline">
              <a rel="noreferrer" target='_blank' href="https://drive.google.com/file/d/1DIGI34PRL1oGJuaUFHgCHu_DvtgU3fD-/view?usp=sharing">Download G-key</a>
            </h4>
        </div>
    </div>
  )
}